<template>
  <AppLayout v-if="country" :title="country.name">
    <template #header-content>
      <h1 class="page-header-title">{{ country.name }}</h1>
    </template>

    <DataTable
      ref="dataTable"
      title="Country Regions"
      :endpoint="`countries/${country.id}/country-regions`"
      :sort="{ name: 'desc' }"
      searchable
    >
      <template v-slot:buttons>
        <Button type="primary" @click="newCountryRegion()">NEW COUNTRY REGION</Button>
      </template>

      <template v-slot:rows="rows">
        <DataTableRow v-for="(row, i) in rows" :key="i" :row="row">
          <DataTableColumn title="Name" sortable="name">{{ row.name }}</DataTableColumn>
          <DataTableColumn title="Users" sortable="users_count" width="max">
            <span v-if="row.users_count">{{ row.users_count | number }}</span>
            <span v-else class="text-supplement">-</span>
          </DataTableColumn>
          <DataTableColumn title="Abbreviation" sortable="abbreviation" width="max">{{
            row.abbreviation
          }}</DataTableColumn>

          <template v-slot:actions>
            <Dropdown icon="ellipsis-v" class="flip-menu">
              <DropdownLink icon="pencil" @click="editCountryRegion(row)">Edit</DropdownLink>
            </Dropdown>
          </template>
        </DataTableRow>
      </template>
    </DataTable>
  </AppLayout>
</template>

<script>
import NewCountryRegionModal from "@/modals/Countries/NewCountryRegionModal.vue"
import EditCountryRegionModal from "@/modals/Countries/EditCountryRegionModal.vue"

export default {
  metaInfo() {
    return { title: this.country?.name }
  },
  data: () => ({
    country: null
  }),
  async created() {
    const { data } = await this.$api.get(`countries/${this.$route.params.id}`)
    this.country = data.item
  },
  methods: {
    async newCountryRegion() {
      if (
        await this.$modal.show(NewCountryRegionModal, {
          countryId: this.country.id
        })
      ) {
        this.$refs.dataTable.reload()
      }
    },
    async editCountryRegion(countryRegion) {
      if (
        await this.$modal.show(EditCountryRegionModal, {
          countryId: this.country.id,
          countryRegionId: countryRegion.id
        })
      ) {
        this.$refs.dataTable.reload()
      }
    }
  }
}
</script>
